import React from 'react';
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import './App.scss';
import {authService} from "./modules/_shared/auth/AuthService";
import {LoginStandalonePage} from "./modules/auth/login/LoginStandalonePage";
import {RegisterStandalonePage} from "./modules/auth/register/RegisterStandalonePage";
import MainLayout from "./modules/main/MainLayout";

const App:React.FC = () => {
	console.warn('APP render');
	authService.tryConnect().then();
	
	return (
		<Router>
			<Switch>
				<Route exact path="/login" component={LoginStandalonePage}/>
				<Route exact path="/register" component={RegisterStandalonePage}/>
				<Route path="/" component={MainLayout}/>
			</Switch>
		</Router>
	);
};

export default App;
