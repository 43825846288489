import {restClient} from "../RestClient";

export const authService = new class AuthService {
	private connected_progress:boolean = false;
	private connected:boolean = false;

	private csrfTokenAcquired:boolean = false;

	async tryConnect():Promise<boolean> {
		this.connected_progress = true;
		const req = await restClient.get('/api/auth/renewal');
		this.csrfTokenAcquired = true;
		if (req.code === 204) {
			// cannot auto-connect, no existing cookies
			this.connected = false;
			this.connected_progress = false;
			return false;
		} else if (req.code === 200) {
			// auto-connection successful
			this.connected = true;
			this.connected_progress = false;
			return true;
		}

		//TODO
		return false;
	}

	isConnected():boolean {
		return this.connected;
	}

	isCsrfTokenAcquired():boolean {
		return this.csrfTokenAcquired;
	}
}();
