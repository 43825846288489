import {ResponseWrapper} from "../../../../shared/src/types/protocol/ResponseWrapper";
import {authService} from "./auth/AuthService";

export const restClient = new class RestClient {
	private readonly baseUrl:string;
	
	// private pendingRequests:PendingRequest[] = [];

	constructor() {
		this.baseUrl = document.head.getAttribute('data-api-url')!;
	}

	async get<T>(relativeUrl:string):Promise<ResponseWrapper<T>> {
		const request = await fetch(this.baseUrl + relativeUrl, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		});
		if (request.status === 204) {
			// no content
			return {code:204, status:request.statusText} as ResponseWrapper<T>;
		}
		const json = await request.json();
		return json as ResponseWrapper<T>;
	}

	async post<T>(relativeUrl:string, body:any):Promise<ResponseWrapper<T>> {
		const csrfOk = authService.isCsrfTokenAcquired();
		if (!csrfOk) {
			await authService.tryConnect();
		}

		const requestOptions:any = {
			method:'POST',
			headers:{
				'Accept': 'application/json',
				'Content-Type':'application/json',
				//TODO add csrf token from cookie
			},
			credentials: 'same-origin',
			body:JSON.stringify(body)
		};

		const request = await fetch(this.baseUrl + relativeUrl, requestOptions);
		if (request.status === 204) {
			// no content
			return {code:204, status:request.statusText} as ResponseWrapper<T>;
		}
		const json = await request.json();
		return json as ResponseWrapper<T>;
	}
}();
