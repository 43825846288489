import React, {MouseEvent} from "react";
import "./ItemsLongPage.scss"

type Props = {}

type State = {
	error?:Error
	connectedLogin?:string
	loginPending:boolean
	isConnected:boolean
}

/**
 * Display the register form for a new user to register
 */
export class ItemsLongPage extends React.Component<Props, State> {
	constructor(props:Props) {
		super(props);
		this.state = {
			isConnected:false,
			loginPending:true,
		};
	}

	handleLoginClick = (event:MouseEvent<HTMLElement>) => {
		console.warn('login click');
		// this.setState({
		// 	tempComponent:Object.assign(this.state.tempComponent, {done:event.target.checked})
		// })
	};

	render() {
		return (
			<div className="ItemsLongPage">
				<h1>Items long page</h1>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
				<div className="Long-entry">Blabla</div>
			</div>
		);
	}
}
