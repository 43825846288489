import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faCube,
	faCubes,
	faHome,
	faSignInAlt,
	faSignOutAlt,
	faTools
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import {Link, NavLink, Redirect, Route, Switch} from "react-router-dom";
import {authService} from "../_shared/auth/AuthService";
import {AdminPage} from "../admin/AdminPage";
import {HomePage} from "../home/HomePage";
import {ItemsLongPage} from "../items/ItemsLongPage";
import {ItemsPage} from "../items/ItemsPage";
import './MainLayout.scss';

type Props = {}

type State = {
	sideNavCollapsed:boolean,
	isConnecting:boolean
	isConnected:boolean
}

export default class MainLayout extends React.Component<Props, State> {
	constructor(props:Props) {
		super(props);
		this.state = {
			sideNavCollapsed:'true' === localStorage.getItem('side-nav-collapsed'),
			isConnecting:true,
			isConnected:false
		};
	}

	async componentDidMount():Promise<void> {
		const isConnected = await authService.tryConnect();
		this.setState({
			isConnecting:false,
			isConnected:isConnected
		})
	}

	handleSideNavCollapseClick = () => {
		localStorage.setItem('side-nav-collapsed', 'true');
		this.setState({sideNavCollapsed:true});
	};

	handleSideNavExpandClick = () => {
		localStorage.setItem('side-nav-collapsed', 'false');
		this.setState({sideNavCollapsed:false});
	};

	render() {
		return <div className="MainLayout">
			<div className={`Side-nav ${this.state.sideNavCollapsed ? 'collapsed' : ''}`}>
				<div className="Main-logo-part">
					VulnCan
				</div>
				<div className="Side-nav-menu">
					<NavLink className="menu-item" to="home">
						<FontAwesomeIcon icon={faHome} className="item-icon fa-fw"/>
						<span className="item-name">Home Page</span>
					</NavLink>
					<NavLink className="menu-item" to="/items">
						<FontAwesomeIcon icon={faCube} className="item-icon fa-fw"/>
						<span className="item-name">Items Page</span>
					</NavLink>
					<NavLink className="menu-item" to="/items-long">
						<FontAwesomeIcon icon={faCubes} className="item-icon fa-fw"/>
						<span className="item-name">Items long Page</span>
					</NavLink>
					{this.state.isConnected &&
					<NavLink className="menu-item" to="admin">
						<FontAwesomeIcon icon={faTools} className="item-icon fa-fw"/>
						<span className="item-name">Admin Page</span>
					</NavLink>
					}
				</div>
				<div className="Side-nav-collapse-button">
					<button className="button-collapse" onClick={this.handleSideNavCollapseClick}>
						<FontAwesomeIcon icon={faAngleDoubleLeft} className="item-icon fa-fw"/>
						<span className="item-name">Collapse the menu</span>
					</button>
					<button className="button-expand" onClick={this.handleSideNavExpandClick}>
						<FontAwesomeIcon icon={faAngleDoubleRight} className="item-icon fa-fw"/>
					</button>
				</div>
			</div>
			<div className="Main-panel">
				<header className="Top-nav">
					<div className="Top-nav-main">
						TopNav space
					</div>
					<div className="Top-nav-right">
						{!this.state.isConnected ?
							<Link className="menu-item" to="/login">
								<FontAwesomeIcon icon={faSignInAlt} className="item-icon fa-fw"/>
								<span className="item-name">Login</span>
							</Link>
							:
							<Link className="menu-item" to="/logout">
								<FontAwesomeIcon icon={faSignOutAlt} className="item-icon fa-fw"/>
								<span className="item-name">Logout</span>
							</Link>
						}
					</div>
				</header>
				<div className="Main-content">
					<pre>
						{JSON.stringify(this.state, null, 4)}
					</pre>

					{/* Do not include Router as it's already defined at App level */}
					<Switch>
						<Redirect exact from="/" to="/home"/>
						<Route path="/home" component={HomePage}/>
						<Route path="/items" component={ItemsPage}/>
						<Route path="/items-long" component={ItemsLongPage}/>
						<Route path="/admin" component={AdminPage}/>
					</Switch>
				</div>
				<div className="Footer">footer space</div>
			</div>
		</div>
	};
}
