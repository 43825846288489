import React, {MouseEvent} from "react";

import "./LoginStandalonePage.scss";
import {Redirect} from "react-router";
import {restClient} from "../../_shared/RestClient";

type Props = {}

type State = {
	error?:Error
	connectedLogin?:string
	loginPending:boolean
	isConnected:boolean
	login:string
	password:string
	redirectTarget?:string
	errorMessage?:string
}

/**
 * Display the login form for an existing user to enter their credentials
 */
export class LoginStandalonePage extends React.PureComponent<Props, State> {
	constructor(props:Props) {
		super(props);
		this.state = {
			isConnected:false,
			loginPending:true,
			login:'',
			password:''
		};
	}

	handleLoginClick = async (event:MouseEvent<HTMLElement>) => {
		event.preventDefault();

		const password = this.state.password;
		this.setState({password:'', loginPending:true});
		const response = await restClient.post('/api/auth/connection', {login:this.state.login, password:password});
		if (response.code === 200) {
			//TODO implement a from redirect possibility
			this.setState({redirectTarget:'/'});
		} else {
			this.setState({errorMessage:response.data.reason});
		}

		// this.setState({
		// 	tempComponent:Object.assign(this.state.tempComponent, {done:event.target.checked})
		// })
	};

	render() {
		if (this.state.redirectTarget) {
			return <Redirect to={this.state.redirectTarget}/>;
		}

		return (
			<div className="LoginStandalonePage">
				<div className="login-centered-panel">
					<h1>Welcome</h1>
					<form>
						error = {this.state.errorMessage}
						<div className="form-entry">
							<input type="text" name="login" placeholder="Login" autoFocus={true}
							       autoCapitalize="off" autoComplete="off" autoCorrect="off"
							       value={this.state.login}
							       onChange={e => this.setState({login:e.target.value})}
							/>
						</div>

						<div className="form-entry">
							<input type="password" name="password" placeholder="Password"
							       value={this.state.password}
							       onChange={e => this.setState({password:e.target.value})}
							/>
						</div>

						<div className="form-entry">
							<button onClick={this.handleLoginClick}>Connect</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
