import React, {ChangeEvent} from "react";
import {nextId} from "../../misc/nextId";
import {restClient} from "../_shared/RestClient";

type Props = {}

type State = {
	error?:Error
	validationError?:string
	creationPending:boolean
	isLoaded:boolean
	components:ComponentEntry[]
	tempComponent:ComponentEntry
}

type ComponentEntry = {
	id?:string
	title:string
	description:string
	active:boolean
	done:boolean
	version:number
}

/**
 * Display the register form for a new user to register
 */
export class ItemsPage extends React.Component<Props, State> {
	private readonly id:string;
	
	constructor(props:Props) {
		super(props);
		this.id = nextId();
		this.state = {
			isLoaded:false,
			creationPending:false,
			components:[],
			tempComponent:this.createDefaultComponent()
		};
	}

	createDefaultComponent():ComponentEntry {
		return {
			title:'',
			description:'',
			active:true,
			done:false,
			version:1
		}
	}
	
	async componentDidMount():Promise<void> {
		try {
			const response = await restClient.get<ComponentEntry[]>('/api/component');
			const componentList:ComponentEntry[] = response.data!;

			this.setState({
				isLoaded:true,
				components:componentList
			});
		}
		catch(e) {
			this.setState({
				isLoaded:true,
				error: e
			});
		}
	}

	createItem = () => {
		const item = this.state.tempComponent;
		this.setState({
			creationPending:true,
			validationError:undefined
		});
		restClient.post<ComponentEntry>('/api/component', item)
			.then(
				(result) => {
					if (result.code === 201) {
						this.setState({
							creationPending:false,
							isLoaded:true,
							components:this.state.components.concat(result.data),
							tempComponent:this.createDefaultComponent()
						});
					// } else if(result.code > 400){
					// 	this.setState({
					// 		creationPending:false,
					// 		validationError:result.data!.message
					// 	})
					}
				},
				(error) => {
					this.setState({
						creationPending:false,
						error
					});
				}
			);
	};

	handleTitleChange = (event:ChangeEvent<HTMLInputElement>) => {
		this.setState({
			tempComponent:Object.assign(this.state.tempComponent, {title:event.target.value})
		})
	};

	handleDescriptionChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			tempComponent:Object.assign(this.state.tempComponent, {description:event.target.value})
		})
	};

	handleActiveChange = (event:ChangeEvent<HTMLInputElement>) => {
		this.setState({
			tempComponent:Object.assign(this.state.tempComponent, {active:event.target.checked})
		})
	};

	handleDoneChange = (event:ChangeEvent<HTMLInputElement>) => {
		this.setState({
			tempComponent:Object.assign(this.state.tempComponent, {done:event.target.checked})
		})
	};
	
	render() {
		const id = this.id;
		const {error, isLoaded, components} = this.state;
		if (error) {
			return <div>Erreur : {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Chargement…</div>;
		} else {
			return (
				<div className="ItemsPage">
					<div className="new-item">
						<div className="form-entry">
							<label htmlFor={id + '-title'}>Title:</label>
							<input type="text" id={id + '-title'}
							       value={this.state.tempComponent.title}
							       onChange={this.handleTitleChange}/>
						</div>
						<div className="form-entry">
							<label htmlFor={id + '-desc'}>Description:</label>
							<textarea id={id + '-desc'}
							          value={this.state.tempComponent.description}
							          onChange={this.handleDescriptionChange}/>
						</div>
						<div className="form-entry">
							<label htmlFor={id + '-done'}>Done:</label>
							<input type="checkbox" id={id + '-done'}
							       checked={this.state.tempComponent.done}
							       onChange={this.handleDoneChange}/>
						</div>
						<div className="form-entry">
							<label htmlFor={id + '-active'}>Active:</label>
							<input type="checkbox" id={id + '-active'}
							       checked={this.state.tempComponent.active}
							       onChange={this.handleActiveChange}/>
						</div>
						<div className="form-action">
							<div className="actions">
								<button onClick={this.createItem} disabled={this.state.creationPending}>
									Save
								</button>
							</div>
						</div>
						{
							this.state.validationError &&
							<div className="validation-error">{this.state.validationError}</div>
						}
					</div>
					<ul>
						{components.map(c => (
							<li key={c.id}>
								<div className="header">
									<span className="title">{c.title}</span>
									<span className="id">{c.id}</span>
								</div>
								<div className="descriotion">{c.description}</div>
							</li>
						))}
					</ul>
				</div>
			);
		}
	}
}
