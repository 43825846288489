import React from "react";

type Props = {}

type State = {
	error?:Error
	connectedLogin?:string
	loginPending:boolean
	isConnected:boolean
}

/**
 * Display the register form for a new user to register
 */
export class AdminPage extends React.Component<Props, State> {
	constructor(props:Props) {
		super(props);
		this.state = {
			isConnected:false,
			loginPending:true,
		};
	}

	render() {
		return (
			<div className="AdminPage">
				Admin page
			</div>
		);
	}
}
