import React, {MouseEvent} from "react";
import {AuthGetResponse} from "../../../../../shared/src/types/protocol/auth/AuthResponse";

type Props = {}

type State = {
	error?:Error
	connectedLogin?:string
	loginPending:boolean
	isConnected:boolean
}

const baseUrl = document.head.getAttribute('data-api-url');

/**
 * Display the register form for a new user to register
 */
export class RegisterStandalonePage extends React.PureComponent <Props, State> {
	constructor(props:Props) {
		super(props);
		this.state = {
			isConnected:false,
			loginPending:true,
		};
	}

	componentDidMount() {
		fetch(baseUrl + '/api/auth')
			.then(res => res.json())
			.then(
				(result) => {
					const data:AuthGetResponse = result.data;
					if (data.login === null) {
						this.setState({
							isConnected:false,
							loginPending:false,
						});
					} else {
						this.setState({
							isConnected:true,
							loginPending:false,
							connectedLogin:data.login
						});
					}
				},
				(error) => {
					this.setState({
						isConnected:false,
						loginPending:false,
						error
					});
				}
			)
	}

	handleLoginClick = (event:MouseEvent<HTMLElement>) => {
		console.warn('login click');
		// this.setState({
		// 	tempComponent:Object.assign(this.state.tempComponent, {done:event.target.checked})
		// })
	};

	render() {
		return (
			<div className="RegisterStandalonePage">
				Register page
			</div>
		);
	}
}
